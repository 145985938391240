body {
  background-image: url("/img/entrada.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.footer {
  bottom: 0;
  width: 100%;
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
  font-size: small;
  text-align: center;
}

/* Overlaybox no se usa*/
overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #666666;
  opacity: 0.8;
  z-index: 1000;
}

/* Estilo para deshabilitar la app mientras esta activo el loading*/
.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background: #fdfdfd;
  opacity: 0.7;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.dashboard-card {
  box-shadow: 7px 7px 20px #666666;
}

.nombre_usuario {
  color: #fff;
}
.boton_salir {
  color: #fff;
}
.boton_salir:hover {
  color: #ff6347;
  text-decoration: none;
}

.react-bootstrap-table {
  width: 100%;

  overflow: auto;
}

.table.block-last {
  margin-bottom: 0;
}

table.table.table-hover.table-bordered.block-last {
  min-width: 1024px;
}

.block-last th {
  position: sticky;
  top: 0;
  background-color: rgb(233, 233, 233);
}

.block-last th:last-child,
.block-last td:last-child {
  position: sticky;
  right: 0;
  background-color: rgb(233, 233, 233);
}

.table-bordered td {
  border: 1px solid #dee2e6;
  overflow: hidden;
  text-overflow: ellipsis;
}

.borra-archivo-tabla {
  float: right;
}

.transparencia {
  background-image: url("/img/transparente.png");
}
.transparenciagris {
  background-image: url("/img/transparentegris.png");
  color: #ffffff;
}

.reloj {
  font-size: 3vw;
  text-align: left;
  vertical-align: middle;
}
.fecha {
  color: #000000;
  text-shadow: #ffffff 2px 2px 2px;
}
